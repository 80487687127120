<template>
  <div class="forgot__wrapper">
    <h3 class="forgot__title">Forgot Password</h3>
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="forgot__icon">
      <img src="@/assets/images/icon-forgot-1.svg" alt="" />
    </div>
    <ValidationObserver slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="Password"
          rules="required|min:8|hardPassword"
          v-slot="{ errors, failed }"
        >
          <div class="form-row" :class="{ 'has-error': failed }">
            <input
              name="password"
              v-model="form.password"
              ref="password"
              class="form-control"
              type="password"
              placeholder="New Password"
            />
            <span class="help-message">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Repeat New Password"
          rules="required|confirmed:Password|min:8"
          v-slot="{ errors, failed }"
        >
          <div class="form-row" :class="{ 'has-error': failed }">
            <input
              name="password_repeat"
              v-model="form.password_repeat"
              class="form-control"
              type="password"
              placeholder="Repeat New Password"
            />
            <span class="help-message">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="forgot__footer">
          <router-link class="btn btn-primary btn-primary--invert" :to="{ name: 'login' }"
            >Cancel</router-link
          >
          <button type="submit" class="btn btn-primary">Reset</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import alert from '@/mixins/alert';
import autorization from '@/mixins/autorization';

import UserService from '@/services/UserService';

import Alert from '@/components/Alert.vue';

export default {
  components: {
    Alert,
  },
  mixins: [autorization, alert],
  data() {
    return {
      form: {
        password: undefined,
        password_repeat: undefined,
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.removeAlert();
        await UserService.update({ password: this.form.password });
        this.$store.commit('SET_AUTH_USER', true);
        this.$router.push({ name: 'mainAuthorization' });
      } catch (e) {
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
  },
};
</script>
