<template>
  <div class="forgot__wrapper">
    <h3 class="forgot__title">Forgot Password</h3>
    <div class="forgot__icon">
      <img src="@/assets/images/icon-forgot-2.svg" alt="" />
    </div>
    <p style="color:#000">We have sent a link to
      <a :href="form.email">{{form.email}}</a>.
      Check your inbox for more details.
    </p>
    <p style="color:#000">
      You didn‘t receive an email? Click the button below to re-send the email.
    </p>
    <div class="forgot__footer">
      <button class="btn btn-primary" @click="$emit('set-step', 'step-first')">Send again</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
};
</script>
