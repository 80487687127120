var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forgot__wrapper"},[_c('h3',{staticClass:"forgot__title"},[_vm._v("Forgot Password")]),(_vm.text)?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._m(0),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:8|hardPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],ref:"password",staticClass:"form-control",attrs:{"name":"password","type":"password","placeholder":"New Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Repeat New Password","rules":"required|confirmed:Password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_repeat),expression:"form.password_repeat"}],staticClass:"form-control",attrs:{"name":"password_repeat","type":"password","placeholder":"Repeat New Password"},domProps:{"value":(_vm.form.password_repeat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_repeat", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"forgot__footer"},[_c('router-link',{staticClass:"btn btn-primary btn-primary--invert",attrs:{"to":{ name: 'login' }}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Reset")])],1)],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forgot__icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon-forgot-1.svg"),"alt":""}})])}]

export { render, staticRenderFns }