<template>
  <div class="forgot__wrapper">
    <h3 class="forgot__title">Forgot Password</h3>
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="forgot__icon">
      <img src="@/assets/images/icon-forgot-1.svg" alt="" />
    </div>
    <ValidationObserver slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, failed }">
          <div class="form-row" :class="{ 'has-error': failed }">
            <input v-model="form.email" class="form-control" type="email" placeholder="Email" />
            <span class="help-message">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="forgot__footer">
          <router-link class="btn btn-primary btn-primary--invert" :to="{ name: 'login' }">
            Cancel
          </router-link>
          <button type="submit" class="btn btn-primary" >Reset</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import AutorizationService from '@/services/AutorizationService';

import alert from '@/mixins/alert';

import Alert from '@/components/Alert.vue';

export default {
  mixins: [alert],
  components: {
    Alert,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.$route.query.error) {
      this.setAlert({ textArg: 'Invalid Token ! Try again.', variantArg: 'danger' });
    }
  },
  methods: {
    async onSubmit() {
      try {
        await AutorizationService.forgotPassword({ email: this.form.email });
        this.$emit('set-step', 'step-third');
      } catch (e) {
        this.setAlert({ textArg: e.response.data.message, variantArg: 'danger' });
        setTimeout(() => {
          this.hideAlert();
        }, 5000);
      }
    },
  },
};
</script>
