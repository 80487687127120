<template>
  <div class="forgot">
    <div class="container">
      <div class="forgot__inner">
        <keep-alive>
          <component
            :form="form"
            @set-step="setStep($event)"
            :is='activeComponent'>
          </component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import StepFirst from './Steps/StepFirst.vue';
import StepSecond from './Steps/StepSecond.vue';
import StepThird from './Steps/StepThird.vue';

export default {
  components: {
    'step-first': StepFirst,
    'step-second': StepSecond,
    'step-third': StepThird,
  },
  created() {
    if (this.$route.query.step && localStorage.getItem('token')) {
      this.setStep('step-second');
    } else {
      this.setStep('step-first');
      window.history.pushState('', '', '/forgot-password');
    }
  },
  data() {
    return {
      activeComponent: '',
      form: {
        email: '',
      },
    };
  },
  methods: {
    setStep(step) {
      this.activeComponent = step;
    },
  },
  mounted() {
    document.body.classList.add('page-layout');
    document.body.style.background = '';
  },
};
</script>
